import React, { Component } from 'react'
import projets from "../ressources/projets";
import Intro from '../Components/Home/Intro';
import Projet from '../Components/Home/Projet';
import Masonry from 'react-masonry-component';



const masonryOptions = {
  itemSelector: '.grid-item',
  columnWidth: 260,
  gutter: 200,
  fitWidth: true
};




export default class HomePage extends Component {
  render() {
    return (
      <main>
        <Intro></Intro>
        <div className="grid-wrap">
          <Masonry className="grid" options={masonryOptions}>
            {projets.map(projet => <Projet projet={projet}/>)}
          </Masonry>
        </div>
     </main>
    )
  }
}
