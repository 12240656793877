import React from 'react';
// import logo from './logo.svg';
import Header from './Layout/Header';
import Router from "./Router";
import './ressources/Reset.css';
import './ressources/Variables.scss';
import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <Router />
    </>
  );
}

export default App;
