import React, { Component } from 'react';
import { Parallax } from 'react-scroll-parallax';

import './Band.scss';

export default class Band extends Component {
  render() {
    const {currentProject, currentSection} = this.props;
    const bgColor = {
      backgroundColor: currentProject.color,
    };


    return (
      <Parallax ref={ref => (this.parallax = ref)}>
        <section className="section-band">
            <div className="band" style={bgColor}></div>
            <Parallax y={[-10, 10]}>
              <img src={currentSection.image} alt=""/>
            </Parallax>
        </section>
      </Parallax>
    )
  }
}
