import React, { Component } from 'react'
import "./Header.scss";

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="header-fixed">
          <a href="/">Camille Bruneaud</a>
          <div className="header-right">
            <a className={`${window.location.pathname === "/" ? "active" : ""}`} href="/">projets</a>
            <a className={`${window.location.pathname === "/about" ? "active" : ""}`} href="/about">à propos</a>
          </div>
        </div>
      </div>
    )
  }
}
