import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './General.scss';
import './Bubble.scss';


export default class Bubble extends Component {
  render() {
    const {currentProject, currentSection} = this.props;
    const color = {
      color: currentProject.color,
    };

    return (
      <section className="container-fluid text-prez section-bubble pt-100 pt-lg-200">
        <div className="row">
          <div className="col-lg-4 offset-lg-2 pt-5">
            <ScrollAnimation animateIn="fadeInUp" duration={0.8} animateOnce={true}>
              <h2 className="mb-0" style={color}>{currentSection.catchPhrase}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
              <h1 className="mb-20">{currentSection.title}</h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" delay={350} duration={0.6} animateOnce={true}>
              {currentSection.description && <p className="mb-30">{currentSection.description}</p>}
            </ScrollAnimation>
          </div>
          <div className="col-lg-4  bubble-img-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="510.146" height="495.708" viewBox="0 0 510.146 495.708"><path d="M12053.136,2491.36c21.529-101.916,112.343-76.645,193.735-56.493s319.994,33.32,137.419,228.305S12031.606,2593.276,12053.136,2491.36Z" transform="matrix(0.788, -0.616, 0.616, 0.788, -10984.323, 5761.623)" fill={currentProject.color} opacity="0.3"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="488.72" height="509.293" viewBox="0 0 488.72 509.293"><path d="M12053.136,2491.36c21.529-101.916,112.343-76.645,193.735-56.493s319.994,33.32,137.419,228.305S12031.606,2593.276,12053.136,2491.36Z" transform="matrix(0.574, 0.819, -0.819, 0.574, -4673.628, -11257.914)" fill={currentProject.color} opacity="0.1"/></svg>
            <img src={currentSection.image} />
        </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-2 bubble-second-img text-center text-lg-left">
              <div>
              <img src={currentSection.image2} />
              {currentSection.image2Text && <p>{currentSection.image2Text}</p>}
              </div>
          </div>
        </div>
      </section>
    )
  }
}
