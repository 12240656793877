import React, { Component } from 'react'
import './About.scss';
import aboutMeImg from "../ressources/images/itsme.jpg";
import cv from "../ressources/images/CV-CamilleBruneaud.jpg";

export default class AboutPage extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row about mt-lg-200">
          <div className="col-lg-4 offset-lg-2 col-12 col-md-6">
            <h1 className="h2 about-title mb-30 mb-lg-100">À propos</h1>
            {/* <div className="about-image" style ={{backgroundImage: "url("+aboutMeImg+")"}}></div> */}
            <p className="about-text m-0">Hello, je suis Camille Bruneaud.<br/> J'habite actuellement à Limoges et occupe un poste de <span>Graphiste & Webdesigner</span> depuis 3 ans. <br/><br/>
            Je suis passionnée par le graphisme et possède un grand intérêt pour l'illustration et le motion design.<br/><br/>
            Pour en savoir plus sur moi : </p>
            <a href={cv} target="_blank" className="about-cv">mon CV</a>
            <br/>
            <div className="mt-30">
              <a href="https://www.linkedin.com/in/camille-bruneaud-b1a76a113/" target="_blank" className="fa fa-linkedin-square fa-lg mr-30"></a>
              <a href="https://www.instagram.com/_malissia/" target="_blank" className="fa fa-instagram fa-lg"></a>
            </div>
          </div>
          {/* <div className="col-lg-4 offset-lg-1 col-12 col-md-6 mt-50">
            <p className="about-text m-0">Hello, je suis Camille Bruneaud.<br/> J'habite actuellement à Limoges et occupe un poste de <span>Graphiste & Webdesigner</span> depuis 3 ans. <br/><br/>
            Je suis passionnée par le graphisme et possède un grand intérêt pour l'illustration et le motion design. Cela me passionne tellement que lorsque j'ai un peu de temps libre je pratique ces loisirs créatifs.<br/><br/>
            Pour en savoir plus sur moi : </p>
            <a href={cv} target="_blank">mon CV</a>
          </div> */}
        </div>
      </div>
    )
  }
}
