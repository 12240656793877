import Band from "../Components/Project/Band";
import imgGrid from "../Components/Project/imgGrid";
import Simple from "../Components/Project/Simple";
import Bubble from "../Components/Project/Bubble";


// Replaylist
import ReplaylistCover from "./images/replaylist/cover.png";
import ReplaylistBand from "./images/replaylist/intro.png";
import ReplaylistUX from "./images/replaylist/ux.png";
import ReplaylistUI from "./images/replaylist/ui.png";
import ReplaylistIcons from "./images/replaylist/icon.png";
import ReplaylistUF from "./images/replaylist/userflow.png";
import ReplaylistUF2 from "./images/replaylist/userflow-zoom.png";
import ReplaylistGif1 from "./images/replaylist/Home.gif";
import ReplaylistGif2 from "./images/replaylist/Share.gif";
import ReplaylistDesktop from "./images/replaylist/desktop.png";
import ReplaylistDesktop2 from "./images/replaylist/desktop2.png";
import ReplaylistDesktop3 from "./images/replaylist/desktop3.png";
import ReplaylistDesktop4 from "./images/replaylist/desktop4.png";
import ReplaylistDesktop5 from "./images/replaylist/desktop5.png";
import ReplaylistLanding from "./images/replaylist/landing.jpg";
import ReplaylistLanding2 from "./images/replaylist/landing2.jpg";
import ReplaylistLanding3 from "./images/replaylist/landing3.jpg";
import ReplaylistLanding4 from "./images/replaylist/landing4.jpg";



// Coconut
import CoconutCover from "./images/coconut/cover2.png";
import CoconutBand from "./images/coconut/presentation.png";
import CoconutIllus from "./images/coconut/simple.png";
import CoconutImg1 from "./images/coconut/img1.png";
import CoconutImg2 from "./images/coconut/img2.png";
import CoconutImg3 from "./images/coconut/img3.png";
import CoconutLogo from "./images/coconut/logo.png";
import CoconutGif from "./images/coconut/logo.gif";


// Superdev
import SuperdevCover from "./images/superdev/cover.png";
import SuperdevBand from "./images/superdev/intro.png";
import SuperdevImgTop from "./images/superdev/sitemap.png";
import SuperdevImg1 from "./images/superdev/wireframe-home.png";
import SuperdevImg2 from "./images/superdev/wireframe-agency.png";
import SuperdevImgTop2 from "./images/superdev/presentation.jpg";
import SuperdevImg3 from "./images/superdev/home.jpg";
import SuperdevImg4 from "./images/superdev/agency.jpg";
import SuperdevImg5 from "./images/superdev/projets.jpg";
import SuperdevImg6 from "./images/superdev/projet-single.jpg";
import SuperdevImg7 from "./images/superdev/blog.jpg";
import SuperdevImg8 from "./images/superdev/contact.png";
import SuperdevImg9 from "./images/superdev/404.png";
import SuperdevIconSet from "./images/superdev/icon-set.png";
import SuperdevIllu1 from "./images/superdev/illu1.png";
import SuperdevIllu2 from "./images/superdev/illu2.png";



// Powerpoint
import PowerpointCover from "./images/powerpoint/cover.png";
import PowerpointBand from "./images/powerpoint/intro.png";
import PowerpointImg1 from "./images/powerpoint/img1.jpg";
import PowerpointImg2 from "./images/powerpoint/img2.jpg";
import PowerpointImg3 from "./images/powerpoint/img3.jpg";
import PowerpointIconSet from "./images/powerpoint/icon-set.png";
import PowerpointIllu1 from "./images/powerpoint/illu1.png";
import PowerpointIllu2 from "./images/powerpoint/illu2.png";
import PowerpointIllu3 from "./images/powerpoint/illu3.png";
import PowerpointGif1 from "./images/powerpoint/01.gif";
import PowerpointGif2 from "./images/powerpoint/02.gif";


// Formation
import FormationCover from "./images/formation/cover2.png";
import FormationBand from "./images/formation/intro.png";
import FormationMobile from "./images/formation/mobile.png";
import FormationImg1 from "./images/formation/img1.png";
import FormationImg2 from "./images/formation/img2.png";
import FormationImg3 from "./images/formation/img3.png";
import FormationImg4 from "./images/formation/img4.png";



// Illustrations
import IllustrationPersoCover from "./images/illustrations/cover2.png";
import IllustrationPerso1 from "./images/illustrations/ariel.jpg";
import IllustrationPerso2 from "./images/illustrations/ori.jpg";
import IllustrationPerso3 from "./images/illustrations/nymph.jpg";
import IllustrationPerso4 from "./images/illustrations/witch.jpg";
import IllustrationPerso5 from "./images/illustrations/sailor.jpg";
import IllustrationPerso6 from "./images/illustrations/narwhal.jpg";
import IllustrationPerso7 from "./images/illustrations/muse.jpg";
import IllustrationPerso8 from "./images/illustrations/Elisabeth.jpg";
import IllustrationPerso9 from "./images/illustrations/moon.jpg";
import IllustrationPerso10 from "./images/illustrations/malissia.jpg";
import IllustrationPerso11 from "./images/illustrations/mumu.jpg";
import IllustrationPerso12 from "./images/illustrations/elsa.jpg";
import IllustrationPerso13 from "./images/illustrations/death.jpg";
import IllustrationPerso14 from "./images/illustrations/blind.jpg";
import IllustrationPerso15 from "./images/illustrations/voodoo.jpg";
import IllustrationPerso16 from "./images/illustrations/fortune.jpg";
import IllustrationPerso17 from "./images/illustrations/innocence.jpg";
import IllustrationPerso18 from "./images/illustrations/geisha.jpg";
import IllustrationPerso19 from "./images/illustrations/starguardiansquare.jpg";
import IllustrationPerso20 from "./images/illustrations/crown2.jpg";
import IllustrationPerso21 from "./images/illustrations/sabrina.jpg";
import IllustrationPerso22 from "./images/illustrations/kda-full.jpg";
import IllustrationPerso23 from "./images/illustrations/zircon-zoom.jpg";


// Phoenix
import PhoenixCover from "./images/phoenix/cover.png";
import PhoenixBand from "./images/phoenix/intro.png";
import PhoenixImg1 from "./images/phoenix/recherches-lettering.png";
import PhoenixImg2 from "./images/phoenix/recherches-lettering3.png";
import PhoenixImg3 from "./images/phoenix/recherches-lettering2.png";
import PhoenixImg4 from "./images/phoenix/cover-final.jpg";
import PhoenixImg5 from "./images/phoenix/phoenix.jpg";



// Vecteur
import VecteurCover from "./images/vecteurs/cover.png";
import Vecteur1 from "./images/vecteurs/icons.png";
import Vecteur2 from "./images/vecteurs/icons-xmas.png";
import Vecteur3 from "./images/vecteurs/plants.png";
import Vecteur4 from "./images/vecteurs/plants2.png";
import Vecteur5 from "./images/vecteurs/well.png";
import Vecteur6 from "./images/vecteurs/sea.png";
import Vecteur7 from "./images/vecteurs/superdev.jpg";
import Vecteur8 from "./images/vecteurs/flat-game.jpg";
import Vecteur9 from "./images/vecteurs/starguardian-all.jpg";
import Vecteur10 from "./images/vecteurs/starguardian-jinx.jpg";
import Vecteur11 from "./images/vecteurs/sea2.gif";
import Vecteur12 from "./images/vecteurs/girl-faces.jpg";


const project = [
  //Replaylist
  {
    id: "replaylist",
    color: '#F36747',
    title: "Replaylist",
    category: "UX & UI Design",
    cover: ReplaylistCover,
    catchPhrase: 'Jouez et répétez',
    description: 'Replaylist permet de créer des boucles à partir de vidéos et de les jouer autant de fois que souhaité. Un outil permettant de s\'entrainer sur des bouts de vidéo et de répeter par exemple un morceau de musique, ou quelques pas de danse difficiles à mémoriser.',
    roles: ['UX Design', 'UI design', 'Interactions'],
    sections: [
      {
        type: Band,
        image: ReplaylistBand,
      },
      {
        type: Simple,
        catchPhrase: 'High Fidelity',
        title: "Wireframes",
        description: "Après avoir réalisé des wireframes sur papier, permettant de noter rapidement mes idées, j'ai réalisé les wireframes interactif en suivant l'userflow donné.",
        image: ReplaylistUX
      },
      {
        type: Simple,
        catchPhrase: 'UI',
        title: "Design",
        description: "Suite a la validation de l'ergonomie, j'ai pu créer l'identité graphique de l'application. J'ai également réalisé un userflow plus précis avec les maquettes finales pour faciliter le travail des développeurs.",
        images: [ReplaylistUI, ReplaylistUF, ReplaylistUF2]
      },
      {
        type: Simple,
        catchPhrase: 'Guidelines',
        title: "Icon Set",
        description: "Création d'icônes s'harmonisant au style de l'application.",
        customClass: 'small-width',
        image: ReplaylistIcons
      },
      {
        type: Bubble,
        catchPhrase: 'UI',
        title: "Interactions",
        description: "Création d'animation pour illustrer certains principes ainsi que transition de pages.",
        image: ReplaylistGif1,
        image2: ReplaylistGif2,
      },
      {
        type: Simple,
        catchPhrase: 'UI',
        title: "Desktop",
        description: "L'application pourra être utilisée sur ordinateur également. Adaptation de la maquette mobile pour un format web.",
        images: [ReplaylistDesktop, ReplaylistDesktop2, ReplaylistDesktop3, ReplaylistDesktop4, ReplaylistDesktop5]
      },
      {
        type: imgGrid,
        catchPhrase: 'UI',
        title: "Landing Page",
        description: "Pour compléter l'ensemble, réalisation d'une landing page pour présenter l'application et ses fonctionnalités, ainsi que où la télécharger. Voici quelques recherches effectuées :",
        images: [ReplaylistLanding, ReplaylistLanding2, ReplaylistLanding3, ReplaylistLanding4]
      },
    ]
  },
  // Superdev
  {
    id: "superdev",
    color: '#0048BB',
    title: "Superdev",
    category: "UX / UI Design & Illustration",
    cover: SuperdevCover,
    catchPhrase: 'Redesign',
    description: 'Mise à jour ergonomique et visuelle du site internet de l\'agence Superdev. Une agence de création numérique aux multiples talents, avec une identité fun & colorée !',
    roles: ['UX Design', 'UI design', 'Illustration', 'Interactions'],
    link: 'https://superdev.fr/',
    linkText: 'Visitez le site',
    sections: [
      {
        type: Band,
        image: SuperdevBand,
      },
      {
        type: imgGrid,
        catchPhrase: 'Recherches',
        title: "User Flow & UX",
        description: 'Le site présente les projets réalisés, l\'équipe, les compétences. Il possède également un blog où sont partagées les veilles graphiques, technologiques & autres infos.',
        imageTop: SuperdevImgTop,
        images: [SuperdevImg1, SuperdevImg2]
      },
      {
        type: imgGrid,
        catchPhrase: 'Guidelines',
        title: "UI Design",
        description: 'J\'ai pu réaliser la nouvelle identité visuelle en me basant sur notre logos et nos couleurs. L\'ensemble est assez simple mais comporte beaucoup d\'interactions !',
        imageTop: SuperdevImgTop2,
        images: [SuperdevImg3, SuperdevImg4, SuperdevImg5, SuperdevImg6, SuperdevImg7, SuperdevImg8, SuperdevImg9]
      },
      {
        type: Simple,
        customClass: 'small-width',
        catchPhrase: 'Guidelines',
        title: "Icon set",
        image: SuperdevIconSet
      },
      {
        type: Bubble,
        catchPhrase: 'Guidelines',
        title: "Illustrations",
        image: SuperdevIllu1,
        image2: SuperdevIllu2,
      }
    ]
  },
  //Coconut
  {
    id: "coconut",
    color: '#1AC87F',
    title: "Coconut",
    category: "UI Design & Illustration",
    cover: CoconutCover,
    catchPhrase: 'Simplifier les comptes',
    description: 'Coconut est un outil interne à Superdev pour se connecter à plusieurs API développées pour ses clients. L\'équipe peut maintenant utiliser une interface dynamique et simplifiée pour la gestion des comptes et permissions, remplaçant ainsi des lignes de commandes brutes.',
    roles: ['UX Design', 'UI design', 'Illustration'],
    sections: [
      {
        type: Band,
        image: CoconutBand,
      },
      {
        type: Simple,
        catchPhrase: 'Création',
        title: "D'illustrations",
        description: "Afin de rendre l'application attractive j'ai réalisé des illustration mettant en scène des personnages et des noix de coco qui servent à représenter les différentes catégories : User / Rôles / Groups. Les différents rôles eux sont illustrés par différents cocktails.",
        image: CoconutIllus
      },
      {
        type: Bubble,
        catchPhrase: 'Réalisation',
        title: "De logo",
        description: 'Une application a besoin d\'une icône. Une petite noix de coco est alors née, dans un style clair et mignon. Elle représente la simplification de la tâche de l\'utilisateur. Détendez vous et buvez un cocktail ;)',
        image: CoconutLogo,
        image2: CoconutGif,
        image2Text: 'J\'ai également réalisé une animation de logo qui apparait au démarrage de l\'application.'
      },
      {
        type: Simple,
        catchPhrase: 'Maquettes',
        title: "UI Design",
        images: [CoconutImg1, CoconutImg2, CoconutImg3]
      }
    ]
  },
  // Formation
  {
    id: "formation",
    color: '#595DF4',
    title: "Formations",
    category: "UX & UI Design",
    cover: FormationCover,
    catchPhrase: 'Gestion de',
    description: 'Recherches d\'optimisation de l\'expérience utilisateur et du design pour une application de gestion d\'offres d\'emploi et de stages. Elle est composée de deux modes administration ( pour les entreprises ) et user ( pour les candidats ).',
    roles: ['UX Design', 'UI design'],
    sections: [
      {
        type: Band,
        image: FormationBand,
      },
      {
        type: Simple,
        catchPhrase: 'Maquettes',
        title: "Mobile",
        description: 'Réalisation de la version mobile. Une app simple de recherche d\'emploi conçue pour les utilisateurs.',
        image: FormationMobile,
      },
      {
        type: Simple,
        catchPhrase: 'Maquettes',
        title: "Admin",
        description: 'La version desktop admin est conçue pour les entreprises. Elle comprend un dashboard, des actualités entre différents pôles, la liste des emplois et formations et la gestion des candidats.',
        images: [FormationImg1, FormationImg2, FormationImg3, FormationImg4]
      },
    ]
  },
  //PowerPoint
  {
    id: "powerpoint",
    color: '#FE9000',
    title: "Power Point",
    category: "UX & UI Design",
    cover: PowerpointCover,
    catchPhrase: 'Mise en page',
    description: 'Réflexion et mise en page d\'un site web a partir d\'un power point existant. Le but étant de rendre l\'ensemble plus visuel et interactif qu\'une simple présentation.',
    roles: ['UX Design', 'UI design', 'Illustration', 'Interactions'],
    sections: [
      {
        type: Band,
        image: PowerpointBand,
      },
      {
        type: imgGrid,
        catchPhrase: 'Guidelines',
        title: "UI Design",
        description: 'J\'ai réalisé 3 pages par rapport aux nombres de sections présentes dans la présentation. Pour dynamiser l\'ensemble il y a un curseur personnalisé, des animations au scroll, des interactions au survol et au clic.',
        images: [PowerpointImg1, PowerpointImg2, PowerpointImg3]
      },
      {
        type: Simple,
        customClass: 'small-width',
        catchPhrase: 'Guidelines',
        title: "Icon set",
        image: PowerpointIconSet,
      },
      {
        type: Simple,
        catchPhrase: 'Guidelines',
        title: "Illustrations et Animations",
        images: [PowerpointIllu1, PowerpointIllu2, PowerpointIllu3, PowerpointGif1, PowerpointGif2]
      },
    ]
  },
  
  // Phoenix
  {
    id: "phoenix",
    color: '#4C4BEF',
    title: "Phoenix",
    category: "Lettering & Illustration",
    cover: PhoenixCover,
    catchPhrase: 'Charity artbook',
    description: 'Phoenix est une collaboration de plus de 70 artistes pour la création d\'un artbook numérique servant à rassembler des dons pour les feux en Australie. J\'ai pu réaliser le "logo" du projet afin de l\'utiliser en tant que watermark et également une illustration exclusive pour ce projet. Les dons reçus ont été reversés à différentes associations, et l\'artbook n\'a pas été imprimé afin de préserver les forêts.',
    roles: ['Lettering', 'Illustration'],
    sections: [
      {
        type: Band,
        image: PhoenixBand,
      },
      {
        type: imgGrid,
        catchPhrase: 'Recherches',
        title: "Lettering",
        images: [PhoenixImg1, PhoenixImg2]
      },
      {
        type: Simple,
        catchPhrase: 'Final',
        title: "Lettering & Illustration",
        customClass: 'small-width',
        image: PhoenixImg3,
        images: [PhoenixImg4, PhoenixImg5]
      },
    ]
  },
  
  // Illustrations
  {
    id: "illustrations",
    color: '#4C4BEF',
    title: "Illustrations",
    category: "Illustrations",
    cover: IllustrationPersoCover,
    catchPhrase: 'Sélections',
    description: 'Il y a un an, j\'ai décidé de reprendre le dessin, je dessine donc assez régulièment et m\'améliore petit à petit :) ... ',
    link: 'https://www.instagram.com/_malissia/',
    linkText: 'Voir mon instagram',
    sections: [
      {
        type: imgGrid,
        images: [IllustrationPerso20, IllustrationPerso1, IllustrationPerso18, IllustrationPerso19, IllustrationPerso2, IllustrationPerso3, IllustrationPerso4, IllustrationPerso5, IllustrationPerso6, IllustrationPerso7, IllustrationPerso8, IllustrationPerso9, IllustrationPerso10, IllustrationPerso11, IllustrationPerso12, IllustrationPerso21, IllustrationPerso22, IllustrationPerso23,  IllustrationPerso13, IllustrationPerso14, IllustrationPerso15, IllustrationPerso16, IllustrationPerso17]
      }
    ]
  },
  // Autres
  {
    id: "vector",
    color: '#4C4BEF',
    title: "Autre",
    category: "Illustrations / Icons & 3D",
    cover: VecteurCover,
    // catchPhrase: 'Créations en',
    description: 'Un petit regroupement de mes créations vectorielles, icon set & illustrations.',
    sections: [
      {
        type: Simple,
        images: [Vecteur1, Vecteur2, Vecteur3, Vecteur4]
      },
      {
        type: Simple,
        title: "3D",
        description: 'Je pratique également la 3D lors de mon temps libre, voici quelques essais réalisés sous blender 2.8',
        images: [Vecteur6, Vecteur11, Vecteur5, Vecteur7, Vecteur8, Vecteur9, Vecteur10, Vecteur12]
      }
    ]
  },
  
]

export default project