import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './General.scss';

export default class Simple extends Component {
  render() {
    const {currentProject, currentSection} = this.props;
    const color = {
      color: currentProject.color,
    };

    if(!currentSection.customClass) currentSection.customClass = "";


    return (
      <section className="container-fluid text-prez section-simple pt-100 pt-lg-200">
        <div className="col-lg-5 offset-lg-2 pt-5">
          <ScrollAnimation animateIn="fadeInUp" duration={0.8} animateOnce={true}>
            <h2 className="mb-0" style={color}>{currentSection.catchPhrase}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
            <h1 className="mb-20">{currentSection.title}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
            {currentSection.description && <p className="mb-30">{currentSection.description}</p>}
          </ScrollAnimation>
        </div>
        <div className={`col-lg-8 mx-auto mt-50 mt-lg-100 ${currentSection.customClass}`}>
          {currentSection.image && <img className="w-100" src={currentSection.image} />}
          {currentSection.images && currentSection.images.map(image => <img className="mb-30 mb-lg-100 w-100" src={image} />)}
        </div>
      </section>
    )
  }
}
