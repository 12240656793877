import React, { Component } from 'react';
import './Intro.scss';


export default class Intro extends Component {
  
  
  render() {
    return (
        <div className="home-intro">
            <div className="home-intro-content">
                <h2 className="home-intro-text">Hello ! Je m'appelle Camille Bruneaud,<br/>
                 je suis <span>UX / UI Designer</span><br/> & également intéressée par le <i>design graphique</i>, <i>l'illustration</i> et le <i>motion design</i>.</h2>
            </div>
        </div>
    )
  }
}
