import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Masonry from 'react-masonry-component';
import './General.scss';
import './imgGrid.scss';


const masonryOptions = {
    columnWidth: '.grid-sizer',
    gutter: '.gutter-sizer',
    itemSelector: '.grid-item-50',
    percentPosition: true,
};



export default class imgGrid extends Component {
  state = {
    loadedImages: []
  }

  onload2promise(obj){
    return new Promise((resolve, reject) => {
        obj.onload = () => resolve(obj);
        obj.onerror = reject;
    });
  }

  async componentDidMount(){
    for(let image of this.props.currentSection.images){
      const img = new Image();
      img.src = image; // by setting an src, you trigger browser download
    
      await this.onload2promise(img);
      this.setState({loadedImages: [...this.state.loadedImages, image]});
    }
  }
  render() {
    const {currentProject, currentSection} = this.props;
    const color = {
      color: currentProject.color,
    };

    return (
      <section className="container-fluid text-prez section-simple pt-100 pt-lg-200">
        <div className="col-lg-5 offset-lg-2 pt-5">
          <ScrollAnimation animateIn="fadeInUp" duration={0.8} animateOnce={true}>
            <h2 className="mb-0" style={color}>{currentSection.catchPhrase}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
            <h1 className="mb-20">{currentSection.title}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
            {currentSection.description && <p className="mb-30">{currentSection.description}</p>}
          </ScrollAnimation>
        </div>

        <div className="col-lg-8 mx-auto mt-50 mt-lg-100">
            {currentSection.imageTop && <img src={currentSection.imageTop} />}
        </div>
        
        <Masonry className="img-grid col-lg-8 mx-auto mt-50 mt-lg-100" options={masonryOptions} onImagesLoaded={this.handleImagesLoaded}>
            <div className="grid-sizer"></div>
            <div className="gutter-sizer"></div>
            {this.state.loadedImages.map(image => <div className="grid-item-50"><img src={image} /></div>)}
        </Masonry>

      </section>
    )
  }
}
