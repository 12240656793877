import React, { Component } from 'react'
import {Switch, Route, BrowserRouter} from "react-router-dom";
import HomePage from './Pages/HomePage';
import ProjectPage from './Pages/ProjectPage';
import AboutPage from './Pages/AboutPage';

export default class componentName extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/projet/:id" component={ProjectPage}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route component={HomePage} />
        </Switch>
      </BrowserRouter>
    )
  }
}
