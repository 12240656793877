import React, { Component } from 'react';
// import projets from "../ressources/projets";
import Intro from '../Components/Project/Intro';
import projects from "../ressources/projets";

export default class ProjectPage extends Component {
  render() {
    let idProject = this.props.match.params.id;
    let currentProjectIndex = projects.findIndex(e => e.id === idProject);
    let currentProject = projects[currentProjectIndex];
    let nextProject = projects[currentProjectIndex + 1 == projects.length ? 0 : currentProjectIndex + 1]
    return (
      <div id={currentProject.id}>
       <Intro currentProject={currentProject}/>
       {currentProject.sections.map(section => <section.type currentProject={currentProject} currentSection={section} />)}
       <div className="container-fluid text-center mt-50 mb-50 mt-lg-150 mb-lg-100">
          <a href={`/projet/${nextProject.id}`}>
            Projet suivant : {nextProject.title}
          </a>
       </div>
      </div>
    )
  }
}
