import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import slugify from 'react-slugify';
import './Intro.scss';
import './General.scss';

export default class Intro extends Component {
  render() {
    const {currentProject} = this.props;
    const color = {
      color: currentProject.color,
    };


    return (
      <section className="container-fluid section-intro text-prez">
        <div className="col-lg-5 offset-lg-2 pt-5">
          <ScrollAnimation animateIn="fadeInUp" duration={0.8} animateOnce={true}>
            <h2 className="mb-0" style={color}>{currentProject.catchPhrase}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={0.8} animateOnce={true}>
            <h1 className="mb-20">{currentProject.title}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={300} duration={0.8} animateOnce={true}>
            <p className="mb-30">{currentProject.description}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={500} duration={1.2} animateOnce={true}>
            {currentProject.roles && 
              <div className="role">
                <h4>Mon rôle dans le projet</h4>
                <div className="roles">
                <style dangerouslySetInnerHTML={{__html: `
                  .roles>p:before{
                    background-color: ${currentProject.color};
                  }
                `}} />
                {currentProject.roles && currentProject.roles.map(role => <p className={slugify(role)}>{role}</p>)}
                </div>
              </div>
            }
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={700} duration={1.2} animateOnce={true}>
            {currentProject.link &&
              <a href={currentProject.link} target="_blank" className="mt-30" style={color}>{currentProject.linkText && currentProject.linkText}</a>
            }
          </ScrollAnimation>
        </div>
      </section>
    )
  }
}
