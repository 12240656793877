import React, { Component } from 'react';
import Charming from 'react-charming';
import './Projet.scss';


export default class Projet extends Component {
  
  hover = () =>{
    // letters anim
    let letters = this.title.childNodes[0].childNodes;
    letters.forEach((letter, index) =>{
      window.TweenMax.to(letter, .2, {
        ease: "expo.in",
        delay: index * .05,
        y: '-50%',
        opacity: 0,
        onComplete: () => {
          window.TweenMax.to(letter, 0.5, {
            ease: "expo.out",
            startAt: {y: '70%', opacity: 0},
            y: '0%',
            opacity: 1
          });
        }
      });
    });
  }

  hoverMove = (event) => {
    let boundingRectangle = this.tilt.getBoundingClientRect();
    let moveX = +(event.clientX - boundingRectangle.x - boundingRectangle.width / 2) / 5;
    let moveY = +(event.clientY - boundingRectangle.y - boundingRectangle.height / 2) / 5;
    window.TweenMax.to(this.image, 0.8, {
      x: moveX,
      y: moveY,
      ease: "expo.out"
    });
    window.TweenMax.to(this.title, 0.8, {
      x: (moveX) + 5,
      y: (moveY) + 5,
      ease: "expo.out"
    });
    window.TweenMax.to(this.category, 0.8, {
      x: (moveX) - 8,
      y: (moveY) - 8,
      ease: "expo.out"
    });
    window.TweenMax.to(this.bg, 1.5, {
      ease: "expo.out",
      scale: 1.1
    });
  }

  resetHover = () => {
    if(window.TweenMax) window.TweenMax.to(this.image, 0.8, {
      ease: "expo.out",
      x: 0,
      y: 0,
    });
    if(window.TweenMax) window.TweenMax.to(this.title, 0.8, {
      ease: "expo.out",
      x: 0,
      y: 0,
    });
    if(window.TweenMax) window.TweenMax.to(this.category, 0.8, {
      ease: "expo.out",
      x: 0,
      y: 0,
    });
    if(window.TweenMax) window.TweenMax.to(this.bg, 1.5, {
      ease: "expo.out",
      scale: 1
    });
    // letters anim
    let letters = this.title.childNodes[0].childNodes;
    letters.forEach((letter, index) =>{
      window.TweenMax.to(letter, .2, {
        ease: "power1.in",
        delay: index * .05,
        y: '50%',
        opacity: 0,
        onComplete: () => {
          window.TweenMax.to(letter, 1, {
            ease: "elastic.out(1, 0.4)",
            startAt: {y: '-70%', opacity: 0},
            y: '0%',
            opacity: 1
          });
        }
      });
    });
  }

  
  render() {
    const {projet} = this.props;
    return (
      <a href={`/projet/${projet.id}`} className="grid-item Tilt-inner"  onMouseMoveCapture={this.hoverMove} onMouseEnter={this.hover} ref={ref=>this.tilt=ref} onMouseLeave={this.resetHover}>
        <div className="grid-item--bg" ref={ref=>this.bg=ref}></div>
        <div className="grid-item--wrap" ref={ref=>this.image=ref}>
          <img src={projet.cover} className="grid-item--img" alt=""/>
        </div>
        <h4 className="grid-item--category" ref={ref=>this.category=ref}>{projet.category}</h4>
        <div className='h3 grid-item--title' ref={ref=>this.title=ref}>
          <Charming className='category-letters'  letters={projet.title}/>
        </div>
      </a>
    )
  }
}
